const baseUrl = '';

export const environment = {
  production: false,
  baseUrl,
  auth: {
    domain: 'siemens-qa-00169.eu.auth0.com',
    clientId: 'YeHhm3L9NCeQ6cuYcCBj7gO3Iu7XGx5U',
    authorizationParams: {
      audience: 'lcs-contract-dashboard-dev',
      redirect_uri: window.location.origin,
    },
    errorPath: '/error',
    audience: 'lcs-contract-dashboard-dev'
  },
  cacheLocation: 'localstorage',
  httpInterceptor: {
    allowedList: [`${baseUrl}/*`],
  },
  roles: {
    adminRole: 'LCS Contract Dashboard Dev Admin',
    userRole: 'LCS Contract Dashboard Dev User',
    orgaRole: 'LCS Contract Dashboard Dev Management'
  }
};
